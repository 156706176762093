import { reactive } from 'vue'
import { usePermissionStore } from '@admin/store/modules/permission'
import {
  postMenuKeep,
  postMenuUnKeep,
  getMenuKeepList,
} from '@admin/api/system/systemMenu/systemMenu'
import { router } from '@admin/router'
import { useMenuState } from '@admin/layouts/default/menu/components/Menu/useMenuState'
import { useSystemMenuStore } from '@admin/store/modules/systemMenu'
import { useTimeoutFn } from '@vueuse/core'
import {
  flattenMenuTree,
  findMenuParentIds,
  findAllCollectionMenu,
  treeFilter,
} from '@admin/utils/systemMenu/index'
import { useMultipleTabStore } from '@admin/store/modules/multipleTab'
import { useHandleIframe } from '@admin/views/common/iframe/hooks/useHandleIframe'
import { useAppStore } from '@admin/store/modules/app'
import { useUserStore } from '@admin/store/modules/user'
import { useLocale } from 'framework/locales/useLocale'
import { useTabs } from '@admin/hooks/web/useTabs'

const systemStatus = reactive({
  switchMenuLoading: false,
  init: false,
})

export const useSystemInfo = () => {
  const treeFind = (tree, func) => {
    for (const data of tree) {
      if (func(data)) return data
      if (data.children) {
        const res = treeFind(data.children, func)
        if (res) return res
      }
    }
    return null
  }
  // 设置菜单active
  const setMenuActive = (parentsId) => {
    const appStore = useAppStore()
    const { setSelectedKeys, setMenuOpenKeys } = useMenuState()
    const pop = parentsId.pop()
    setSelectedKeys(`${pop}`)
    if (!appStore.getMenuSetting.collapsed) {
      parentsId.forEach((val) => {
        setMenuOpenKeys(`${val}`)
      })
    }
  }
  /**
   * 初始化事件
   * @param routerList
   * @returns
   */
  const init = async (routerList: any[]) => {
    // const { changeLocale, getLocale } = useLocale()

    // 先过滤hidden的属性
    const list = treeFilter(routerList, (item) => {
      return !Number(item.hidden)
    })
    // 1、初始化接受参数，保存到store
    const systemMenuStore = useSystemMenuStore()
    // 2、记录所有的路有信息
    systemMenuStore.setAllSystemList(list)
    // 2、找出所有的收藏列表，保存到store
    const collectionList = findAllCollectionMenu()
    systemMenuStore.setCollectionList(collectionList)
    // 扁平化所有的menu
    const menuList = flattenMenuTree(list)

    systemMenuStore.setAllMenuList(menuList)
    // 3、记录初始化状态
    systemStatus.init = true

    // const defaultLocal = await import(`../../../locales/${getLocale.value}.ts`)

    // changeLocale(getLocale.value, defaultLocal?.default)
  }

  const switchLocale = async (lang) => {
    const { refreshPage } = useTabs()
    const { changeLocale } = useLocale()
    const { clearCacheTabs } = useMultipleTabStore()

    const defaultLocal = await import(`../../../locales/${lang}.ts`)
    await changeLocale(lang, defaultLocal?.default)
    refreshPage() //刷新当前页
    clearCacheTabs() //清空顶部tab缓存，不删除tab页
  }
  /**
   * 获取当前系统的tabs,按照打开时间排序
   */
  const findLastTabById = () => {
    const systemMenuStore = useSystemMenuStore()
    const tabStore = useMultipleTabStore()
    const filterTab = tabStore.tabList
      .filter((v) => `${v.meta.systemId}` === `${systemMenuStore.curSystem}`)
      .sort((a: any, b: any) => a.meta.openTime - b.meta.openTime)
    return filterTab
  }
  /**
   * 切换系统
   * @param systemId
   */
  const switchSystem = (system: any, routeInfo?: any) => {
    const systemMenuStore = useSystemMenuStore()
    if (systemMenuStore.allSystemList.length === 0 || !system) return false
    // 1、系统相同、不需要切换
    if (system.id === systemMenuStore.curSystem) return false
    const permissionStore = usePermissionStore()
    // 2、设置左边菜单内容
    systemMenuStore.setCurSystem(system.id)
    permissionStore.setMenuList(system.children)
    // 3、判断当前路由是否已经属于当前系统的列表中， 属于就不跳转。只是菜单变化
    const { currentRoute } = router
    if (`${system.id}` === `${currentRoute.value.meta.systemId}`) {
      return false
    }
    // 4、查看当前系统是否有切换记录
    const tabs = findLastTabById() as any
    const activeRecord = tabs.length ? tabs[tabs.length - 1] : null
    const realToRoute = routeInfo
      ? routeInfo
      : activeRecord
      ? activeRecord
      : treeFind(system.children, (v) => {
          return !v.children
        })
    const menu = systemMenuStore.allMenuList.find((v) =>
      realToRoute?.name === 'iframe'
        ? `${v.id}` === `${realToRoute.query.menuId}`
        : v.path === realToRoute.path,
    )
    const menuIds = findMenuParentIds(menu)
    setMenuActive(menuIds)
    return realToRoute
  }
  /**
   * 路由判断
   */
  const judgeRouteInfo = async (to) => {
    // 1、先判断是否已经初始化完成
    const systemMenuStore = useSystemMenuStore()
    if (!systemStatus.init) return
    if (!systemMenuStore.allSystemList.length) return true
    // to.path === '/',默认为登陆
    if (to.path === '/') {
      return await loginSelectSystem()
    }
    if (to.path === '/workbench/workbench') {
      const { resetSelectKeys } = useMenuState()
      resetSelectKeys()
    }
    // 2、从tabList找出path === to.path的信息，取meta.systemId 判断
    const tabStore = useMultipleTabStore()
    const tabRecord = tabStore.tabList.find((v) => v.fullPath === to.fullPath)
    if (tabRecord && tabRecord.meta.systemId) {
      let checkExistenceMenu = systemMenuStore.allMenuList.find((v) => v.path === to.path)
      if (tabRecord.meta.menuId) {
        checkExistenceMenu = systemMenuStore.allMenuList.find((v) => v.id === tabRecord.meta.menuId)
      }
      const menuIds = findMenuParentIds(checkExistenceMenu)
      // 2.1、判断当前系统是否为收藏
      if (systemMenuStore.curSystem === 'collection') {
        const permissionStore = usePermissionStore()
        // 2.1.1、查看是否有收藏menu了。没有才需要走api获取
        const collMenuList = permissionStore.menuList
        if (!collMenuList.length) {
          // 通过接口获取收藏信息
          const menuData = await apiCollectionMenu()
          permissionStore.setMenuList(menuData.data)
          setMenuActive(menuIds)
        } else {
          // 存在菜单层级
          if (checkExistenceMenu) {
            setMenuActive(menuIds)
          }
        }
        return false
      }
      // 2.2、判断to系统跟当前系统是否相同，相同，只需设置menu状态
      if (systemMenuStore.curSystem === tabRecord.meta.systemId) {
        const permissionStore = usePermissionStore()
        if (!permissionStore.menuList.length) {
          const menuList = systemMenuStore.allSystemList.find((v) => v.id === to.meta.systemId)
          permissionStore.setMenuList(menuList.children)
        }
        menuIds && menuIds.length && setMenuActive(menuIds)
        return false
      } else {
        const newMenuList = systemMenuStore.allSystemList.find(
          (v) => v.id === tabRecord.meta.systemId,
        )
        // 路由不在当前系统路有列表里，系统切换系统
        switchSystem(newMenuList, to)
      }
    } else {
      const permissionStore = usePermissionStore()
      const menuList = permissionStore.menuList
      // 收藏下，不做任何操作
      if (systemMenuStore.curSystem === 'collection') {
        if (!menuList.length) {
          // 通过接口获取收藏信息
          const menuData = await apiCollectionMenu()
          // 设置左侧菜单数据
          permissionStore.setMenuList(menuData.data)
          const menu = systemMenuStore.allMenuList.find((v) => v.path === to.path)
          const menuIds = findMenuParentIds(menu)
          setMenuActive(menuIds)
        }
        return
      }
      if (systemMenuStore.allSystemList.length === 0) return false
      // 有菜单则设置菜单内容
      // 查看是否有menu了。没有默认第一个系统
      if (!menuList.length) {
        const system = systemMenuStore.allSystemList[0]
        permissionStore.setMenuList(system.children)
        systemMenuStore.setCurSystem(system.id)
      }
    }
  }
  /**
   * 通过收藏接口拿到收藏列表
   * @returns
   */
  const apiCollectionMenu = async () => {
    let originData = [] as any
    let newMenu = [] as any
    try {
      const result = await getMenuKeepList()
      originData = result.data
      for (let index = 0; index < result.data.length; index++) {
        if (result.data[index].children && result.data[index].children?.length) {
          newMenu = [...newMenu, ...result.data[index].children]
        }
      }
      return { data: newMenu, originData: originData }
    } catch (error) {
      return { data: newMenu, originData: originData }
    }
  }
  // 去收藏列表第一条
  const toCollectionOne = () => {
    const systemMenuStore = useSystemMenuStore()
    let menu = systemMenuStore.allMenuList.find(
      (v) => v.path === systemMenuStore.collectionList[0].path,
    )
    if (menu.iframe_url) {
      const { conversionUrl } = useHandleIframe()
      menu = conversionUrl(menu)
    }
    const menuIds = findMenuParentIds(menu)
    setMenuActive(menuIds)
    return menu
  }
  /**
   * 选择收藏系统 curSystem = 'collection'
   */
  const systemCollection = async () => {
    const systemMenuStore = useSystemMenuStore()
    if (systemMenuStore.curSystem === 'collection') return
    const { currentRoute } = router
    systemMenuStore.setCurSystem('collection')
    // 通过接口获取收藏信息
    const menuData = await apiCollectionMenu()
    // 设置左侧菜单数据
    const permissionStore = usePermissionStore()
    permissionStore.setMenuList(menuData.data)
    // 判断当前路由是否已经属于当前系统的列表中， 属于就不跳转。只是菜单变化
    const openedRoute = systemMenuStore.collectionList.find((v) =>
      currentRoute.value.name === 'iframe'
        ? `${v.id}` === `${currentRoute.value.query.menuId}`
        : v.path === currentRoute.value.path,
    )
    if (openedRoute) {
      return false
    }
    // 查看当前系统是否有切换记录
    const tabStore = useMultipleTabStore()
    const activeRecord = tabStore.tabList[tabStore.tabList.length - 1]
    if (activeRecord) {
      const findOpen = systemMenuStore.collectionList.find((v) =>
        activeRecord.name === 'iframe'
          ? `${v.id}` === `${activeRecord.query.menuId}`
          : v.path === activeRecord.path,
      )
      if (findOpen) {
        const menuIds = findMenuParentIds(findOpen)
        setMenuActive(menuIds)
        return activeRecord
      } else {
        return toCollectionOne()
      }
    } else {
      return toCollectionOne()
    }
  }
  /**
   * 设置为第一个系统
   */
  const goFirstSystem = (toMenuUrl = true) => {
    const systemMenuStore = useSystemMenuStore()
    const permissionStore = usePermissionStore()
    const firstMenu = systemMenuStore.allMenuList.find((v) => !v.children)
    const menuIds = findMenuParentIds(firstMenu)
    const firstSystem = systemMenuStore.allSystemList.find((v) => v.id === menuIds[0])
    permissionStore.setMenuList(firstSystem.children)
    systemMenuStore.setCurSystem(menuIds[0])
    if (toMenuUrl) {
      setMenuActive(menuIds)
      useTimeoutFn(() => {
        router.replace(firstMenu.path)
      }, 500)
    }
  }
  /**
   * 登录时候判断是否有收藏列表
   */
  const loginSelectSystem = async () => {
    const systemMenuStore = useSystemMenuStore()
    const collectionList = systemMenuStore.collectionList

    // 老板不需要去工作台
    const userStore = useUserStore()
    if ([16, 2970201, 2370201, 2790200, 4050200].includes(userStore.getUserId)) {
      if (collectionList && collectionList.length) {
        const result = await systemCollection()
        await router.replace(result ? result.path : collectionList[0].path)
      } else {
        goFirstSystem()
      }
    } else {
      // 普通人
      if (collectionList && collectionList.length) {
        await systemCollection()
      }
      goFirstSystem(false)
      await router.replace('/workbench/workbench')
    }
  }
  /**
   * 添加菜单收藏
   * @param item
   * @returns
   */
  const addMenuRate = async (item: any, pIds: any) => {
    try {
      const result = await postMenuKeep({ menu_ids: pIds })
      const systemMenuStore = useSystemMenuStore()
      systemMenuStore.updatedCollectionList(item)
      return result
    } catch (error) {
      return []
    }
  }
  /**
   * 取消菜单收藏
   * @param item
   * @returns
   */
  const cancelMenuRate = async (item: any) => {
    const systemMenuStore = useSystemMenuStore()
    try {
      const result = await postMenuUnKeep({ menu_ids: [item.id] })
      systemMenuStore.updatedCollectionList(item, 'del')
      if (systemMenuStore.curSystem === 'collection') {
        const permissionStore = usePermissionStore()
        const res = await apiCollectionMenu()
        if (res.data && res.data.length) {
          permissionStore.setMenuList(res.data)
          // let firstMenu = treeFind(res.originData, (v) => {
          //   return !v.children
          // })
          // const menuIds = findMenuParentIds(firstMenu)
          // setMenuActive(menuIds)
          // if (firstMenu && firstMenu.iframe_url) {
          //   const { conversionUrl } = useHandleIframe()
          //   firstMenu = conversionUrl(firstMenu)
          // }
          // router.push(firstMenu.path)
        } else {
          const { currentRoute } = router
          const firstMenu = systemMenuStore.allMenuList.find(
            (v) => v.path === currentRoute.value.path,
          )
          const menuIds = findMenuParentIds(firstMenu)
          const firstSystem = systemMenuStore.allSystemList.find((v) => v.id === menuIds[0])
          systemMenuStore.setCurSystem(firstSystem.id)
          permissionStore.setMenuList(firstSystem.children)
        }
      }
      return result
    } catch (error) {
      return []
    }
  }
  return {
    systemStatus,
    init,
    switchSystem,
    judgeRouteInfo,
    systemCollection,
    treeFind,
    addMenuRate,
    cancelMenuRate,
    loginSelectSystem,
    switchLocale,
  }
}
